import { cn } from "~/lib/utils";

function GridItems({ items }: { items: PrismaJson.SummarySectionItem[] }) {
  return (
    <ul className="flex pt-4 pb-1">
      {items.map((item, index) => (
        <li
          key={index}
          className={cn("px-6 min-w-[18rem] w-[calc(100%/4)]", index > 0 ? "border-l border-divider" : "")}
        >
          {item.url ? (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="block -mx-4 px-2 -my-0.5 py-0.5 hover:bg-foreground/5 rounded-md focusable"
            >
              {item.category && <div className="text-muted-foreground pr-2">{item.category}</div>}
              {item.title && <h4 className="font-bold mb-2">{item.title}</h4>}
              <div>{item.summary}</div>
            </a>
          ) : (
            <>
              {item.title && <h4 className="font-bold mb-2">{item.title}</h4>}
              <div>{item.summary}</div>
            </>
          )}
        </li>
      ))}
    </ul>
  );
}

export function ItemIcon({ type }: { type: PrismaJson.SummarySectionItemType }) {
  if (type === "post") {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16">
        <path
          className="fill-foreground/40"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 2H11C11.2652 2 11.5196 2.10536 11.7071 2.29289C11.8946 2.48043 12 2.73478 12 3V12C12 12.2652 11.8946 12.5196 11.7071 12.7071C11.5196 12.8946 11.2652 13 11 13H1C0.734784 13 0.48043 12.8946 0.292893 12.7071C0.105357 12.5196 0 12.2652 0 12L0 3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2ZM2.5 11H5.5C5.63261 11 5.75979 10.9473 5.85355 10.8536C5.94732 10.7598 6 10.6326 6 10.5C6 10.3674 5.94732 10.2402 5.85355 10.1464C5.75979 10.0527 5.63261 10 5.5 10H2.5C2.36739 10 2.24021 10.0527 2.14645 10.1464C2.05268 10.2402 2 10.3674 2 10.5C2 10.6326 2.05268 10.7598 2.14645 10.8536C2.24021 10.9473 2.36739 11 2.5 11ZM2.5 5H9.5C9.63261 5 9.75979 4.94732 9.85355 4.85355C9.94732 4.75979 10 4.63261 10 4.5C10 4.36739 9.94732 4.24021 9.85355 4.14645C9.75979 4.05268 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 4.05268 2.14645 4.14645C2.05268 4.24021 2 4.36739 2 4.5C2 4.63261 2.05268 4.75979 2.14645 4.85355C2.24021 4.94732 2.36739 5 2.5 5ZM2.5 8H9.5C9.63261 8 9.75979 7.94732 9.85355 7.85355C9.94732 7.75979 10 7.63261 10 7.5C10 7.36739 9.94732 7.24021 9.85355 7.14645C9.75979 7.05268 9.63261 7 9.5 7H2.5C2.36739 7 2.24021 7.05268 2.14645 7.14645C2.05268 7.24021 2 7.36739 2 7.5C2 7.63261 2.05268 7.75979 2.14645 7.85355C2.24021 7.94732 2.36739 8 2.5 8Z"
        />
      </svg>
    );
  }
  if (type === "discussion") {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16">
        <path
          className="fill-foreground/40"
          d="M10 2H2C0.895 2 0 2.895 0 4V9C0 10.105 0.895 11 2 11H3.5V14.5C3.5 14.692 3.61 14.867 3.783 14.951C3.852 14.984 3.926 15 4 15C4.111 15 4.222 14.963 4.312 14.891L8.5 11H9.999C11.104 11 11.999 10.105 11.999 9V4C11.999 2.895 11.104 2 9.999 2H10Z"
        />
      </svg>
    );
  }
  return null;
}

function ListItemContent({ item }: { item: PrismaJson.SummarySectionItem }) {
  return (
    <>
      <span className="absolute left-2.5 top-2">
        <ItemIcon type={item.type} />
      </span>
      {(item.title || item.category) && (
        <h4 className="inline font-bold mb-2">
          {item.category ? (
            <span className="text-muted-foreground pr-2 mr-2 border-r border-divider">{item.category}</span>
          ) : null}
          {item.title ? `${item.title}: ` : null}
        </h4>
      )}
      <span>{item.summary}</span>
    </>
  );
}

function ListItems({ items }: { items: PrismaJson.SummarySectionItem[] }) {
  return (
    <ul className="pl-1 pt-2">
      {items.map((item, index) => (
        <li key={index} className="">
          {item.url ? (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="block pl-8 pr-6 py-1 relative hover:bg-foreground/5 rounded-md focusable"
            >
              <ListItemContent item={item} />
            </a>
          ) : (
            <div className="pl-8 pr-6 py-1 relative">
              <ListItemContent item={item} />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
}

function DiscussionSymbol({ discussionUrl }: { discussionUrl: string }) {
  return (
    <a
      href={discussionUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="inline-flex items-center justify-center group relative focusable h-8 m-0 p-2  rounded-md hover:bg-foreground/5"
    >
      <svg viewBox="0 0 16 16" className="fill-foreground/40 size-4">
        <title>Discussion</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.85352 12.3304L11.6859 15.891C11.7759 15.963 11.8869 16 11.9979 16C12.0719 16 12.1459 15.984 12.2149 15.951C12.3879 15.867 12.4979 15.692 12.4979 15.5V12H13.9979C15.1029 12 15.9979 11.105 15.9979 10V5C15.9979 3.89532 15.1034 3.00051 13.9988 3V7C13.9988 9.20957 12.2084 11 9.99881 11H9.28551L7.85352 12.3304Z"
        />
        <path d="M10 0H2C0.895 0 0 0.895 0 2V7C0 8.105 0.895 9 2 9H3.5V12.5C3.5 12.692 3.61 12.867 3.783 12.951C3.852 12.984 3.926 13 4 13C4.111 13 4.222 12.963 4.312 12.891L8.5 9H9.999C11.104 9 11.999 8.105 11.999 7V2C11.999 0.895 11.104 0 9.999 0H10Z" />
      </svg>
    </a>
  );
}

export function SectionSymbol({ symbol, className }: { symbol: string; className?: string }) {
  const isTeam = symbol.startsWith("team:");
  return (
    <span className={cn("inline-flex items-center justify-center text-primary w-8", className)}>
      {isTeam ? (
        <svg width="16" height="16" className="fill-primary inline-block relative top-[1px]">
          <path d="M8 6C6.3 6 5 4.7 5 3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3ZM10 15a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2.586a1 1 0 0 0-.293-.707l-1.414-1.414A1 1 0 0 1 4 9.586V8a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v1.586a1 1 0 0 1-.293.707l-1.414 1.414a1 1 0 0 0-.293.707V15ZM2 5C.9 5 0 4.1 0 3s.9-2 2-2 2 .9 2 2-.9 2-2 2ZM3.293 10.693A1 1 0 0 1 3 9.986V7a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v1.586a1 1 0 0 0 .293.707l.414.414a1 1 0 0 1 .293.707V13a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1.186a1 1 0 0 0-.293-.707l-.414-.414ZM14 5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2ZM12.707 10.693A1 1 0 0 0 13 9.986V7a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1.586a1 1 0 0 1-.293.707l-.414.414a1 1 0 0 0-.293.707V13a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1.186a1 1 0 0 1 .293-.707l.414-.414Z" />
        </svg>
      ) : (
        symbol
      )}
    </span>
  );
}

export function SourceSummarySection({
  summarySection,
  viewMode,
}: {
  summarySection: PrismaJson.SummarySection;
  viewMode: PrismaJson.ViewMode;
}) {
  return (
    <div className="py-5 border-b border-divider">
      <h3 className="font-serif font-semibold text-xl px-1 flex flex-row items-start">
        {summarySection.url ? (
          <a
            href={summarySection.url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block leading-8 pl-3 pr-2 rounded-md hover:bg-foreground/5 focusable"
          >
            <SectionSymbol symbol={summarySection.symbol} className="-ml-3" />
            {summarySection.category && (
              <span className="text-muted-foreground">
                {summarySection.category}
                <span className="border-r border-divider pr-2 mr-2 h-5 inline-block relative top-0.5" />
              </span>
            )}
            {summarySection.name}
          </a>
        ) : (
          <span className="inline-block leading-8 pl-3 pr-2">
            <SectionSymbol symbol={summarySection.symbol} className="-ml-3" />
            {summarySection.category && <span className="text-muted-foreground">{summarySection.category} · </span>}
            <span>{summarySection.name}</span>
          </span>
        )}
        {summarySection.discussionUrl && <DiscussionSymbol discussionUrl={summarySection.discussionUrl} />}
      </h3>
      {viewMode === "grid" ? <GridItems items={summarySection.items} /> : <ListItems items={summarySection.items} />}
    </div>
  );
}
